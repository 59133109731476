import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import io from 'socket.io-client';
import { SERVER_URL } from './lib/utils/constant';
import { useDispatch } from 'react-redux';
import LoggedOutPage from './views/LoggedOut';
require('src/scss/icon.min.css')

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

let socket = null

const searchParams = new URLSearchParams(document.location.search)
let agent_id = searchParams.get('agent_id');
if(agent_id == null) {
  agent_id = localStorage.getItem('agent_id');
  
  console.log('************* agent_id = ', agent_id);
  socket = io(SERVER_URL, {
    transports: ['websocket'], // Force WebSocket
    query: { agent_id }
  });
}

const App = ()=> {
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if(socket) {
      socket.on('mobile/allsports', function(payload){
        dispatch({type:'allsports', data: payload});
      });
      socket.on('mobile/live-events', function(payload){
        dispatch({type:'live-events', data: payload});
      });
    }
  }, [])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout />} />
          <Route path="/logout" element={<LoggedOutPage />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App