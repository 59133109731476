import React from 'react';
import './index.css'; // CSS 파일을 import
require ('src/scss/pc/style.css');

const LoggedOutPage = () => {
  return (
    <div className="logout">
        <h1>로그아웃 되었습니다.</h1>
        <p>세션이 만료되었거나 사이트 점검중입니다.</p>
    </div>
  )
}

export default LoggedOutPage;
