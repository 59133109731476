import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import PropTypes, { func } from "prop-types";
import { useSelector } from 'react-redux';
import { TR, getSportTitle } from 'src/lib/utils/common';
import api from 'src/lib/api';
import {IMG_SERVER_URL} from '../../lib/utils/constant';
var moment = require('moment'); // require

const SportTab = ({sportId, handleChangeSport, isLive, countryCode, handleChangeCountry, leagueId, handleChangeLeague}) => {
    const NavData = useSelector((state) => isLive === true?state.sockets.LiveEvents: state.sockets.NavData);
    let [sportData, setSportData] = useState([]);
    let [popLeagues, setPopLeagues] = useState([]);

    let [totalV1, settotalV1] = useState(0);
    let [totalV2, settotalV2] = useState(0);

    let [popTotal, setPopTotal] = useState(0);
    
    useEffect(() => {
        fetchSports();
    }, []);
    
    const fetchSports = async ()=>{
        try {
            let popularLeagues = await api.get('/sport-connect-api/popular-leagues', {pageIndex: 1, pageSize: 5});

            setPopLeagues(popularLeagues.data.data);
            let total = 0;
            popularLeagues.data.data.forEach(league => total += league.MatchesCount);
            setPopTotal(total);
        } catch (ex) {
            console.log(ex);
        }

        let sportData1 = await api.post('/bethistory-api/all-sports', {pageIndex: 1, pageSize: 5});
        let sData = sportData1.data.data;
        
        setSportData(sData);

        for(let i=0; i < NavData.length; i++){
            let item = NavData[i];
            for(let j=0; j < sportData.length; j++){
                let ritem = sportData[j];
                if(item['SportName'] == ritem['name'])
                {
                    item['show_name'] = ritem['show_name'];
                    item['on_off'] = ritem['on_off'];
                    item['m_order'] = ritem['m_order'];
                }
            }
            if(item['show_name'] == null){
                item['show_name'] = item['SportName'];
                if(item['SportName_ko'] !== null && item['SportName_ko'] !== undefined)
                    item['show_name'] = item['SportName_ko'];
                item['on_off'] = 1;
                item['m_order'] = 1000;
            }
        }

        for(let i=0; i < NavData.length-1; i++)
            for(let j=i+1;j<NavData.length;j++)
                if(NavData[i]['m_order'] > NavData[j]['m_order'])
                {
                    let item = NavData[i];
                    NavData[i] = NavData[j];
                    NavData[j] = item;
                }
    }

    function renderAll(){
        let totalCount = 0;
        
        console.log('NavData = ', NavData);
        
        if(NavData.length > 0){
            for(let j=0; j < sportData.length; j++){
                let ritem = sportData[j];
                //console.log(ritem);
                if(parseInt(ritem['on_off']) == 1)
                {
                    let found = 0;
                    for(let i=0; i < NavData.length; i++){
                        let item = NavData[i];
                        if(item['SportId'] == ritem['sid'])
                            found++;
                    }

                    if(found == 0)
                    {
                        //console.log('should insert');
                        let item = {SportId: ritem['sid'], SportName: ritem['name'], SportName_ko: ritem['show_name'], eventsQuantity: 0, liveEventsQuantity: 0, m_order:ritem['m_order'], on_off:ritem['on_off']};
                        
                        NavData.push(item);
                    }
                }
            }

            for(let i=0; i < NavData.length; i++){
                let item = NavData[i];
                //console.log(item);
            }
        }

        for(let i=0; i < NavData.length; i++){
            let item = NavData[i];
            if(sportData != null){
                for(let j=0; j < sportData.length; j++){
                    let ritem = sportData[j];
                    if(item['SportName'] == ritem['name'])
                    {
                        item['show_name'] = ritem['show_name'];
                        item['on_off'] = ritem['on_off'];
                        item['m_order'] = ritem['m_order'];
                    }
                }
                if(item['show_name'] == null){
                    item['show_name'] = item['SportName'];
                    if(item['SportName_ko'] !== null && item['SportName_ko'] !== undefined){
                        item['show_name'] = item['SportName_ko'];
                    }
                    item['on_off'] = 1;
                    item['m_order'] = 1000;
                }
            }
            totalCount += isLive===true?item['liveEventsQuantity']: item['eventsQuantity'];
        }

        for(let i=0; i < NavData.length-1; i++)
            for(let j=i+1;j<NavData.length;j++)
                if(NavData[i]['m_order'] > NavData[j]['m_order'])
                {
                    let item = NavData[i];
                    NavData[i] = NavData[j];
                    NavData[j] = item;
                }

        
        if(NavData.length > 0 && totalV1 === 0 && totalV2 === 0){
            settotalV1(0);
            settotalV2(0);

            let totalVV1 = 0;
            let totalVV2 = 0;
            for(let i=0; i < NavData.length; i++)
            {
                let item = NavData[i];
                if(isLive)
                    totalVV1 += parseInt(item['liveEventsQuantity']);
                else
                    totalVV2 += parseInt(item['eventsQuantity']);
                //console.log('totalVV2',totalVV2, item['eventsQuantity']);
            }
            settotalV1(totalVV1);
            settotalV2(totalVV2);
            //console.log('totalV2',totalV2);
        }
    }

    return (
        <>
            <div className="sports_tab_w">
                <ul className="sub_tab sports_tab">
                    {
                        renderAll()
                    }
                </ul>
            </div>

            <div className="contents_box_left">
                <div className="left_sports_box">
                    <ul>
                        <li className={sportId == -1?"active":""} onClick={()=>handleChangeSport(-1)}><a>전체<img src="images/pc/left_arrow.png" className="left_arrow"/></a></li>
                        <div className="left_hide_list" style={{display: sportId == -1 ? 'block' : "none"}}>
                            <ul>
                                <li className="active"><a ><img src="images/pc/@country.png" className="left_country_img"/>세계 <span className="left_sports_odd">{isLive===true?totalV1:totalV2}</span></a></li>
                            </ul>
                        </div>
                    </ul>
                    <ul>
                        <li className={sportId == -2?"active":""} onClick={()=>handleChangeSport(-2)}>
                            <a>
                                인기리그<img src="images/pc/left_arrow.png" className="left_arrow"/>
                                <span className="left_sports_odd">{popTotal}</span>
                            </a>
                            
                        </li>
                        <div className="left_hide_list" style={{display: sportId == -2 ? 'block' : "none"}}>
                            <ul>
                                <li className={leagueId == -1?"active":""} onClick={() => handleChangeLeague(-1)}>
                                    <a>전체<span className="left_sports_odd">{popTotal}</span></a>
                                </li>
                                {
                                    popLeagues.map((league) => (
                                        <li key={league['name']} className={leagueId == league['name']?"active":""} onClick={() => handleChangeLeague(league.name)}>
                                            <a>
                                                <img src={IMG_SERVER_URL+league.image_path.replace('https://adm-sportscenter.com/', '')} className="left_country_img"/>
                                                {league.show_name}
                                                <span className="left_sports_odd">{league['MatchesCount']}</span>
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </ul>
                    {
                        NavData.map((item, index) => (
                            item['on_off'] == 1 && 
                        <ul key={index}>
                            <li className={sportId == parseInt(item['SportId'])?"active":""} onClick={()=>handleChangeSport(item['SportId'])} key={item['SportId']}>
                                <a>
                                    <img src={"assets/images/sports/"+item.SportName+".svg"} className="left_icon"/>
                                    {TR(item, 'show_name')} 
                                    {/* <img src="images/pc/left_arrow.png" className="left_arrow"/> */}
                                    <span className="left_sports_odd">{isLive === true ? item['liveEventsQuantity'] : item['eventsQuantity']}</span>
                                </a>
                            </li>
                            <div className="left_hide_list" style={{display: sportId == item['SportId'] ? 'block' : "none"}}>
                                <ul>
                                    <li className={countryCode == 'global'?"active":""} key={'global'} onClick={()=>handleChangeCountry('global')}>
                                        <a>
                                            세계 
                                            <span className="left_sports_odd">{isLive===true?item['liveEventsQuantity']: item['eventsQuantity']}</span>
                                        </a>
                                    </li>
                                    {
                                        item['countries']?.map((country, index) => (
                                            <li className={countryCode == country['Code']?"active":""} key={country['Code']} onClick={()=>handleChangeCountry(country['Code'])}>
                                                <a>
                                                    {country.LocationName_ko} 
                                                    <span className="left_sports_odd">{isLive===true?country['liveEventsQuantity']: country['eventsQuantity']}</span>
                                                </a>
                                                <div className="left_hide_list" style={countryCode == country['Code'] ? {} : {display: countryCode == country['Code'] ? "block" : "none"}}>
                                                    <ul>
                                                    {
                                                        country.Leagues?.map((league, index) => (
                                                            <li className={leagueId == league['LeagueId']?"active":""} key={index} onClick={(event)=>{ event.stopPropagation(); handleChangeLeague(league['LeagueId'])}}>
                                                                <a>
                                                                    <span className='sidebar_league_name'>{league.LeagueName_ko ?? league.LeagueName}</span>
                                                                    <span className="left_sports_odd">{isLive===true?league['liveEventsQuantity']: league['eventsQuantity']}</span>
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                    </ul>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </ul>
                        ))
                    }
                    {
                        // addScript()
                    }
                </div>
            </div> 

        </>
    )
}

SportTab.propTypes = {
    sportId : PropTypes.string.isRequired,
    handleChangeSport: PropTypes.func.isRequired,
    isLive : PropTypes.bool,
    countryCode : PropTypes.string.isRequired,
    handleChangeCountry: PropTypes.func.isRequired,
    leagueId : PropTypes.string.isRequired,
    handleChangeLeague: PropTypes.func.isRequired,
};

export default React.memo(SportTab)
