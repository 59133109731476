import { translate } from "./common";

//export const SERVER_URL     = `http://219.127.148.237:5002/`;
// export const SERVER_URL     = `https://api-sportscenter.com/`;
export const IMG_SERVER_URL      = `https://adm-spo-sports.com/`;
export const SERVER_URL          = `https://api.spo-sports.com/`;
//export const SERVER_URL          = `https://spo-sports.com/`;
export const SERVER_BASE_URL     = `${SERVER_URL}sport-api`;
export const SERVER_BET_URL      = `${SERVER_URL}bet-api`;
export const SERVER_HISTORY_URL  = `${SERVER_URL}bethistory-api`;
export const SERVER_USER_URL     = `${SERVER_URL}user-api`;

export const STAKE_ARRAY = {
    "KRW": [5* 1000, 10* 1000, 50* 1000, 100* 1000, 1000* 1000],
    "CNY": [5, 10, 50, 100, 500, 1000],
    "USD": [5, 10, 50, 100, 500, 1000],
};

export const STAKE_ARRAY1 = {
    "KRW": [5* 1000, 10* 1000, 50* 1000, 100* 1000, 500* 1000, 1000* 1000],
    "CNY": [5, 10, 50, 100, 500, 1000],
    "USD": [5, 10, 50, 100, 500, 1000],
};

export const EVENT_TYPE = Object.freeze({
    ENDED: 3,
    LIVE : 2,
    PRELIVE : 1,
});

export const VIEW_TYPE = Object.freeze({
    LIVE : 0,
    TODAY : 1,
    EARLY: 2,
    OUTRIGHT: 3,
});

export const SYSTEM_BET_TYPES = Object.freeze({
    3 : ['TRIXIE', 'PATENT'],
    4:  ['YANKEE', 'LUCKY15'],
    5:  ['SUPER_YANKEE', 'LUCKY31'],
    6:  ['HEINZ', 'LUCKY63'],
    7:  ['SUPER_HEINZ'],
    8:  ['GOLIATH'],
});

export const SYSTEM_BET_COUNTS = Object.freeze({
    'TRIXIE' : 4,
    'PATENT' : 7,
    'YANKEE' : 11,
    'LUCKY15' : 15,
    'SUPER_YANKEE' : 26,
    'LUCKY31' : 31,
    'HEINZ' : 57,
    'LUCKY63' : 63,
    'SUPER_HEINZ' : 120,
    'GOLIATH' : 247,
});


export const BET_TYPES = Object.freeze({
    SINGLE : 'SINGLE',
    PARLAY : 'PARLAY',
    FOLD : 'FOLD',
    TRIXIE : 'TRIXIE',
    PATENT : 'PATENT',
    YANKEE : 'YANKEE',
    LUCKY15 : 'LUCKY15',
    SUPER_YANKEE: 'SUPER_YANKEE',
    LUCKY31: 'LUCKY31',
    HEINZ : 'HEINZ',
    LUCKY63 : 'LUCKY63',
    SUPER_HEINZ: 'SUPER_HEINZ',
    GOLIATH: 'GOLIATH',
});

export const LIST_PARLAY_MARKETS = [1, 2, 3, 5, 6, 17, 1312];

export const LIST_1X2_MARKETS = [1, 41, 42, 161, 409];

export const VIEW_MODES_ID = Object.freeze({
    SINGLE : 0,
    DOUBLE : 1,
});

export const VIEW_MODES = [{value : VIEW_MODES_ID.SINGLE, label: translate('singleView')}, {value : VIEW_MODES_ID.DOUBLE, label: translate('doubleView')}];

export const LANGUAGE_ID = Object.freeze({
    KOREAN  : 'ko',
    ENGLISH : 'en',
});

export const LANGUAGES = [{value : LANGUAGE_ID.KOREAN, label: '한국어'}, {value : LANGUAGE_ID.ENGLISH, label: 'English'}];

export const THEMES = [{value : 'dark', label: 'Dark'}, {value : 'light', label: 'Light'}];


export const ACCEPT_ODDS_CHANGED_MODE = Object.freeze({
    ALL : 'ALL',
    BETTER : 'BETTER',
    NOT: 'NOT',
});

export const MARKET_MENU_ID = Object.freeze({
    ALL_MARKETS : 0,
    MAIN : 1,
    HALVES: 2,
    GOALS: 3,
    CORNERS: 4,
    SPECIALS: 5,
    CARDS: 6,
    PERIODS: 7, 
    QUATERS: 8,
    PLAYER: 9,
});

export const MARKET_MENUS = [
    {id: MARKET_MENU_ID.ALL_MARKETS , name: translate('allMarkets')},
    {id: MARKET_MENU_ID.MAIN        , name: translate('main')},
    {id: MARKET_MENU_ID.HALVES      , name: translate('halves')},
    {id: MARKET_MENU_ID.PLAYER       , name: translate('player')},
    {id: MARKET_MENU_ID.GOALS       , name: translate('score')},
    {id: MARKET_MENU_ID.CORNERS     , name: translate('corners')},
    {id: MARKET_MENU_ID.SPECIALS    , name: translate('specials')},
]

export const MARKET_MENUS1 = [
]

 var MARKET_TYPES = {};
 MARKET_TYPES[MARKET_MENU_ID.ALL_MARKETS] = [];
 MARKET_TYPES[MARKET_MENU_ID.MAIN]        = [1,50,282,284,409,410,415,419,427,429,528,52,63,226,286,411,412,464,554];
 MARKET_TYPES[MARKET_MENU_ID.HALVES]      = [3,13,53,61,64,65,66,67,68,95,201,250,281,283,307,342,386,407,408,433,526,447,449,448,450,467,468,555];
 MARKET_TYPES[MARKET_MENU_ID.GOALS]       = [5,51,62,72,73,74,75,76,170,198,199,240,241,242,243,285,289,290,291,292,293,294,350,351,366,367,368,369,387,388,398,400,406,529,438,479,480,481,482];
 MARKET_TYPES[MARKET_MENU_ID.CORNERS]     = [];
 MARKET_TYPES[MARKET_MENU_ID.SPECIALS]    = [4, 12, 51, 69, 78, 79, 82, 83, 84, 85, 96, 88, 89, 90, 91, 96, 97, 98, 99, 106, 107, 108, 109, 110, 111, 113, 114, 117, 1310, 1335, 1889, 2733];
 MARKET_TYPES[MARKET_MENU_ID.CARDS]       = [19, 82, 157, 158, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, ];
 MARKET_TYPES[MARKET_MENU_ID.PERIODS]     = [];
 MARKET_TYPES[MARKET_MENU_ID.QUATERS]     = [161, 162, ];
 MARKET_TYPES[MARKET_MENU_ID.PLAYER]     = [2,21,28,29,30,31,45,46,47,48,77,101,102,129,132,133,135,136,153,154,155,156,157,158,162,165,166,179,180,181,182,183,184,185,186,187,214,220,221,222,223,236,287,288,308,309,337,352,353,354,355,401,402,403,404,405,414,416,417,418,427,436,437,523,525,534,466,469,475,476,477,478,553,557,558,559];
 export var MARKET_TYPES;

 export const SPORT_ID = Object.freeze({
    SOCCER : 6046,
    BEACH_VOLLEY: 621569,
    TABLE_TENNIS: 265917,
    FUTSAL: 687887,
    HORSE_RACING: 687888,
    GOLF : 687889,
    EGAMES: 687890,
    GREYHOUNDS: 687893,
    TROTTING : 687894,
    SPEEDWAY: 687895,
    FOOTBALL:6046,
    BASKETBALL: 48242,
    NOTOGP: 687896,
    CHESS : 687897,
    VOLLEYBALL: 154830,
    ICEHOCKEY: 35232,
    CRICKET: 452674,
    EQUINE_SPORTS: 291987,
    AUS_RULES: 389537,
    BADMINTON: 1149093,
    FORMULA1: 1149094,
    NASCAR: 1149095,
    HOCKEY: 530129,
    SUPERCARS: 1149096,
    NETBALL: 1149097,
    SURFING: 1149098,
    CYCLING: 1149099,
    GAELIC_SPORTS: 1149100,
    BIATHLON: 1149101,
    MOTORBIKES: 1149102,
    ATHLETICS: 1149103,
    SQUASH: 1149104,
    BASKETBALL3X3: 1149105,
    FLOORBALL: 35706,
    SUMO: 1149107,
    VIRTUAL_SPORTS: 1149108,
    HANDBALL: 35709,
    POLITICS: 1149109,
    WEATHER: 1149110,
    TV_GAMES:1149111,
    LOTTERY: 1149112,
    BOWLS: 1149113,
    POKER: 1149114,
    WATERPOLO: 388764,
    ALPINE_SKIING: 261354,
    SAILING: 1149115,
    HURLING: 1149117,
    SKI_JUMPING: 1149118,
    INDY: 1149119,
    LACROSSE: 1149120,
    OLYMPICS: 1149121,
    SOFTBALL: 1149122,
    BANDY: 46957,
    KABADDI: 1149123,
    MOTOR_SPORTS: 165874,
    BASEBALL: 154914,
    AUDL: 1149124,
    PADEL: 1149125,
    RUGBY_UNION: 274791,
    TRIATHLON: 1149126,
    RUGBY_LEAGUE: 274792,
    CROSS_COUNTRY_SKIING: 1149127,
    CURLING: 307126,
    BOXING: 154919,
    DARTS: 154923,
    TENNIS: 54094,
    AMERICAN_FOOTBALL: 131506,
    SNOOKER: 262622
 });

 export const INCIDENT = Object.freeze({
    CORNER : 1,
    YELLOW_CARD : 6,
    RED_CARD : 7,
    PANELTY : 8,
    GOAL : 9,
    SUBSTITATION : 10,
    OWN_GOAL : 24,
    PANELTY_GOAL : 25,
    PANELTY_NOGOAL : 40,

    FOUL : 12,
    TWO_SCORED : 28,
    THREE_SCORED: 30, 
    TIMEOUT: 31,
    FREE_DRAW: 32,

    ICEHOCKEY_PANELTY : 8,

    BASEBALL_HIT : 33,
 });

 export const SPORT_LIST = [
    {
        "Id": -1,
        "Name": "All",
        "Name_ko": "종합"
    },
    {
        "Id": 6046,
        "Name": "Football",
        "Name_ko": "축구"
    },
    {
        "Id": 48242,
        "Name": "Basketball",
        "Name_ko": "농구"
    },
    {
        "Id": 54094,
        "Name": "Tennis",
        "Name_ko": "테니스"
    },
    {
        "Id": 1149093,
        "Name": "Badminton",
        "Name_ko": "배드민톤"
    },
    {
        "Id": 154914,
        "Name": "Baseball",
        "Name_ko": "야구"
    },
    {
        "Id": 154830,
        "Name": "Volleyball",
        "Name_ko": "배구"
    },
    {
        "Id": 154919,
        "Name": "Boxing",
        "Name_ko": "복싱"
    },
    {
        "Id": 452674,
        "Name": "Cricket",
        "Name_ko": "크리켓"
    },
    {
        "Id": 1149099,
        "Name": "Cycling",
        "Name_ko": "사이클"
    },
    {
        "Id": 687887,
        "Name": "Futsal",
        "Name_ko": "풋살"
    },
    {
        "Id": 131506,
        "Name": "American Football",
        "Name_ko": "미식축구"
    },
    {
        "Id": 35232,
        "Name": "Ice Hockey",
        "Name_ko": "아이스하키"
    },
    {
        "Id": 35709,
        "Name": "Handball",
        "Name_ko": "핸드볼"
    },
    {
        "Id": 274792,
        "Name": "Rugby League",
        "Name_ko": "럭비 리그"
    },
    {
        "Id": 687889,
        "Name": "Golf",
        "Name_ko": "골프"
    },
    {
        "Id": 262622,
        "Name": "Snooker",
        "Name_ko": "당구"
    },
    {
        "Id": 265917,
        "Name": "Table Tennis",
        "Name_ko": "탁구"
    },
    {
        "Id": 274791,
        "Name": "Rugby Union",
        "Name_ko": "럭비 유니언"
    },
    {
        "Id": 687890,
        "Name": "e-sports",
        "Name_ko": "E게임스"
    },
];

export const SPORT_LIST1 = [
    {
        "Id": -1,
        "Name": "All",
        "Name_ko": "전체종목"
    },
    {
        "Id": 6046,
        "Name": "Football",
        "Name_ko": "축구"
    },
    {
        "Id": 154914,
        "Name": "Baseball",
        "Name_ko": "야구"
    },
    {
        "Id": 48242,
        "Name": "Basketball",
        "Name_ko": "농구"
    },
    {
        "Id": 154830,
        "Name": "Volleyball",
        "Name_ko": "배구"
    },
    {
        "Id": 35232,
        "Name": "Ice Hockey",
        "Name_ko": "아이스하키"
    },
    {
        "Id": 687890,
        "Name": "e-sports",
        "Name_ko": "E게임스"
    },
];

 export const SPORT_LIST_ALL = [
    {
        "Id": 621569,
        "Name": "Beach Volleyball",
        "Name_ko": "비치발리볼"
    },
    {
        "Id": 265917,
        "Name": "Table Tennis",
        "Name_ko": "테이블테니스"
    },
    {
        "Id": 687887,
        "Name": "Futsal",
        "Name_ko": "풋살"
    },
    {
        "Id": 687888,
        "Name": "Horse Racing",
        "Name_ko": "경마"
    },
    
    {
        "Id": 687890,
        "Name": "E-Games",
        "Name_ko": "e스포츠"
    },
    {
        "Id": 687893,
        "Name": "Greyhounds",
        "Name_ko": "개경주"
    },
    {
        "Id": 687894,
        "Name": "Trotting",
        "Name_ko": "트로팅"
    },
    {
        "Id": 687895,
        "Name": "Speedway",
        "Name_ko": "스피드웨이"
    },
    {
        "Id": 6046,
        "Name": "Football",
        "Name_ko": "축구"
    },
    {
        "Id": 48242,
        "Name": "Basketball",
        "Name_ko": "농구"
    },
    {
        "Id": 687896,
        "Name": "MotoGP",
        "Name_ko": "모토경주"
    },
    {
        "Id": 687897,
        "Name": "Chess",
        "Name_ko": "체스"
    },
    
    {
        "Id": 35232,
        "Name": "Ice Hockey",
        "Name_ko": "아이스하키"
    },
    {
        "Id": 452674,
        "Name": "Cricket",
        "Name_ko": "크리켓"
    },
    {
        "Id": 291987,
        "Name": "Equine Sports",
        "Name_ko": "Equine Sports"
    },
    {
        "Id": 389537,
        "Name": "Australian Rules",
        "Name_ko": "Australian Rules"
    },
    {
        "Id": 1149093,
        "Name": "Badminton",
        "Name_ko": "배드민톤"
    },
    {
        "Id": 1149094,
        "Name": "Formula 1",
        "Name_ko": "Formula 1"
    },
    {
        "Id": 1149095,
        "Name": "Nascar",
        "Name_ko": "Nascar"
    },
    {
        "Id": 530129,
        "Name": "Hockey",
        "Name_ko": "하키"
    },
    {
        "Id": 1149096,
        "Name": "Supercars",
        "Name_ko": "Supercars"
    },
    {
        "Id": 1149097,
        "Name": "Netball",
        "Name_ko": "Netball"
    },
    {
        "Id": 1149098,
        "Name": "Surfing",
        "Name_ko": "Surfing"
    },
    {
        "Id": 1149099,
        "Name": "Cycling",
        "Name_ko": "Cycling"
    },
    {
        "Id": 1149100,
        "Name": "Gaelic Sports",
        "Name_ko": "Gaelic Sports"
    },
    {
        "Id": 1149101,
        "Name": "Biathlon",
        "Name_ko": "Biathlon"
    },
    {
        "Id": 1149102,
        "Name": "Motorbikes",
        "Name_ko": "Motorbikes"
    },
    {
        "Id": 1149103,
        "Name": "Athletics",
        "Name_ko": "Athletics"
    },
    {
        "Id": 1149104,
        "Name": "Squash",
        "Name_ko": "Squash"
    },
    {
        "Id": 1149105,
        "Name": "Basketball 3X3",
        "Name_ko": "Basketball 3X3"
    },
    {
        "Id": 35706,
        "Name": "Floorball",
        "Name_ko": "Floorball"
    },
    {
        "Id": 1149107,
        "Name": "Sumo",
        "Name_ko": "Sumo"
    },
    {
        "Id": 1149108,
        "Name": "Virtual sports",
        "Name_ko": "Virtual sports"
    },
    {
        "Id": 35709,
        "Name": "Handball",
        "Name_ko": "Handball"
    },
    {
        "Id": 1149109,
        "Name": "Politics",
        "Name_ko": "Politics"
    },
    {
        "Id": 1149110,
        "Name": "Weather",
        "Name_ko": "Weather"
    },
    {
        "Id": 1149111,
        "Name": "TV-Games",
        "Name_ko": "TV-Games"
    },
    {
        "Id": 1149112,
        "Name": "Lottery",
        "Name_ko": "Lottery"
    },
    {
        "Id": 1149113,
        "Name": "Bowls",
        "Name_ko": "Bowls"
    },
    {
        "Id": 1149114,
        "Name": "Poker",
        "Name_ko": "Poker"
    },
    {
        "Id": 388764,
        "Name": "Waterpolo",
        "Name_ko": "Waterpolo"
    },
    {
        "Id": 261354,
        "Name": "Alpine Skiing",
        "Name_ko": "Alpine Skiing"
    },
    {
        "Id": 1149115,
        "Name": "Sailing",
        "Name_ko": "Sailing"
    },
    {
        "Id": 1149117,
        "Name": "Hurling",
        "Name_ko": "Hurling"
    },
    {
        "Id": 1149118,
        "Name": "Ski Jumping",
        "Name_ko": "Ski Jumping"
    },
    {
        "Id": 1149119,
        "Name": "Indy",
        "Name_ko": "Indy"
    },
    {
        "Id": 1149120,
        "Name": "Lacrosse",
        "Name_ko": "Lacrosse"
    },
    {
        "Id": 1149121,
        "Name": "Olympics",
        "Name_ko": "Olympics"
    },
    {
        "Id": 1149122,
        "Name": "Softball",
        "Name_ko": "Softball"
    },
    {
        "Id": 46957,
        "Name": "Bandy",
        "Name_ko": "Bandy"
    },
    {
        "Id": 1149123,
        "Name": "Kabaddi",
        "Name_ko": "Kabaddi"
    },
    {
        "Id": 165874,
        "Name": "Motor Sports",
        "Name_ko": "Motor Sports"
    },
    {
        "Id": 154914,
        "Name": "Baseball",
        "Name_ko": "배구"
    },
    {
        "Id": 1149124,
        "Name": "AUDL",
        "Name_ko": "AUDL"
    },
    {
        "Id": 1149125,
        "Name": "Padel",
        "Name_ko": "Padel"
    },
    {
        "Id": 274791,
        "Name": "Rugby Union",
        "Name_ko": "Rugby Union"
    },
    {
        "Id": 1149126,
        "Name": "Triathlon",
        "Name_ko": "Triathlon"
    },
    {
        "Id": 274792,
        "Name": "Rugby League",
        "Name_ko": "Rugby League"
    },
    {
        "Id": 1149127,
        "Name": "Cross-country Skiing",
        "Name_ko": "Cross-country Skiing"
    },
    {
        "Id": 307126,
        "Name": "Curling",
        "Name_ko": "Curling"
    },
    
    {
        "Id": 154923,
        "Name": "Darts",
        "Name_ko": "Darts"
    },
    {
        "Id": 54094,
        "Name": "Tennis",
        "Name_ko": "테니스"
    },
    {
        "Id": 131506,
        "Name": "American Football",
        "Name_ko": "American Football"
    },
    {
        "Id": 262622,
        "Name": "Snooker",
        "Name_ko": "Snooker"
    }
];